<template>
    <v-card :id="site.protocolSiteId">
        <div class="site-card">
            <v-container>
                <v-row align="start">
                    <v-col style="display: flex; flex-direction: column;">
                            <div class="distance-indicator">
                                ({{site.distance}}
                                    <span v-if="isMiles">
                                        <GlobalLabel label-type="Miles" default="Miles" />
                                    </span>
                                    <span v-else>
                                        <GlobalLabel label-type="Kilometers" default="km" />
                                    </span>)
                            </div>
                            <div class="site-name">
                                {{resolvedSiteName}}
                            </div>
                            <div class="site-address">
                                {{site.address}}
                                <br/>
                                {{site.city}} {{site.state}}, {{site.postalCode}}
                            </div>
                            <div v-if="site.languages">
                                <br/>
                                <b><GlobalLabel label-type="Languages Spoken" default="Languages Spoken" />:</b><br/> {{site.languages}}
                            </div>
                    </v-col>
                    <v-col align-self="center" cols="2">
                            <v-radio :value=site.sfid ripple prepend-icon></v-radio>
                    </v-col>
                </v-row>
                <!-- Removed the Principal Investigator and Languages Spoken section in Nov 2024.
                     Languages Spoken is now included directly below site address info. -->
                <!--
                <v-row align="end" no-gutters v-if="hasAdditionalInformation">
                    <v-col align-self="end">
                        chevron icon
                        <div v-if="!toggled">
                            <v-icon
                                large
                                v-on:click="onExpand()"
                            >
                                mdi-chevron-down
                            </v-icon>
                        </div>
                        <div v-else>
                            <v-icon
                                large
                                v-on:click="onExpand()"
                            >
                                mdi-chevron-up
                            </v-icon>
                        </div>
                    </v-col>
                </v-row>
                -->
                <!-- addl. info -->
                <!--
                <div v-if="toggled">
                    <v-row style="border-top: 2px dashed gray">
                        <v-col v-if="resolvedPrincipalInvestigator">
                            <b><GlobalLabel label-type="Principal Investigator" default="Principal Investigator" />:</b><br/> {{resolvedPrincipalInvestigator}}
                        </v-col>
                        <v-col v-if="site.languages">
                            <b><GlobalLabel label-type="Languages Spoken" default="Languages Spoken" />:</b><br/> {{site.languages}}
                        </v-col>
                    </v-row>
                </div>
                -->
            </v-container>
        </div>
    </v-card>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
    name: 'SiteCard',
    components: {

    },
    props: {
        site: Object
    },
    data: function() {
        return {
            toggled: false
        }
    },
    computed: {
        ...mapGetters(["responses", "responsesByVariableName", "currentCountryConfiguration"]),
        isMiles() {
            return this.lengthUnits === 'Imperial';
        },
        lengthUnits() {
            var countryConfiguration = this.currentCountryConfiguration;
            if (countryConfiguration) {
                return countryConfiguration.country.uomLength;
            }

            // Default to Miles if no country configuration
            return 'Imperial';
        },
        hasAdditionalInformation() {
            return this.site.chiefResearcher || this.site.languages;
        },
        resolvedSiteName() {
            return this.site.siteNameOus || this.site.name;
        },
        resolvedPrincipalInvestigator() {
            return this.site.principalInvestigatorOus || this.site.chiefResearcher;
        }

        // currentValue() {
        //     let value = null;
        //     if (this.element.sfid in this.responses) {
        //         value = this.responses[this.element.sfid].value;
        //     }
        //     return value;
        // }
    },
    methods: {
        onExpand: function(){
            this.toggled = !this.toggled;
        }
    }
}
</script>

<style scoped>
    .site-card {
        font-size: 0.8em;
    }
    .site-name {
        font-weight: bold;
    }
    .distance-indicator {
        color: gray;

    }
    .column {
        text-align: left;
        font-size: 12px;
    }
</style>
